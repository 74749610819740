import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

export const uniqueId = () => Math.floor(Math.random() * Date.now());

function App() {
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    const res = await fetch(
      "https://northwind-worker.lab1.workers.dev/api/classes"
    );
    const data = await res?.json();
    console.log(data);
    setClasses(data?.classes ?? []);
  };

  async function postJSON(data: any) {
    try {
      const response = await fetch(
        "https://northwind-worker.lab1.workers.dev/api/class",
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function postUser() {
    try {
      const response = await fetch(
        "https://northwind-worker.lab1.workers.dev/api/customer",
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify({ hello: "Hêlo" }),
        }
      );

      const result = await response.json();
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const data = {
    id: uniqueId(),
    name: "class " + uniqueId(),
    des: "description for " + uniqueId(),
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} width={120} height={120} alt="logo" />
        <p>CF App</p>
        <button onClick={() => postJSON(data)}>Add Class</button>
        <br />
        <button onClick={() => postUser()}>Add User</button>

        {classes.map((c: any) => (
          <div
            key={c.Id}
            style={{
              background: "grey",
              display: "flex",
              flexDirection: "row",
              gap: 3,
              margin: 12,
              borderRadius: 16,
              padding: "2px 8px",
            }}
          >
            <p>{c.Name}</p>
            <p>{c.Description}</p>
          </div>
        ))}
      </header>
    </div>
  );
}

export default App;
